import React from 'react';
import { Link } from 'gatsby';

import facebook from '../../../static/facebook.svg';
import twitter from '../../../static/twitter.svg';
import ins from '../../../static/ins.svg';
import github from '../../../static/github.svg';

const navigation = [
  { name: 'Overview', href: '#', current: false },
  { name: 'Mission', href: '#', current: false },
  { name: 'Roadmap', href: '#', current: false },
  { name: 'Whitepaper', href: '#', current: false },
  { name: 'Docs', href: '#', current: false },
  { name: 'Press', href: '#', current: false },
];

const links = [
  {
    icon: facebook,
    href: '#',
    name: 'facebook',
  },
  {
    icon: twitter,
    href: '#',
    name: 'twitter',
  },
  {
    icon: ins,
    href: '#',
    name: 'ins',
  },
  {
    icon: github,
    href: '#',
    name: 'github',
  },
];

const Footer = () => {
  const RenderPc = () => {
    return (
      <>
        <div className="flex space-x-12">
          {navigation.map(item => (
            <Link
              to={item.href}
              key={item.name}
              className="text-sm font-light text-gray-500"
            >
              {item.name}
            </Link>
          ))}
        </div>
        <div className="flex my-6 items-center space-x-10">
          {links.map(item => (
            <Link to={item.href} key={item.name}>
              <img src={item.icon} className="h-5" />
            </Link>
          ))}
        </div>
        <div className="font-light text-sm">
          © 2022 House Protocol. All Rights Reserved
        </div>
      </>
    );
  };

  const RenderMb = () => {
    return (
      <>
        <div className="mt-20 grid grid-rows-2 grid-flow-col gap-4">
          {navigation.map(item => (
            <Link
              to={item.href}
              key={item.name}
              className="text-sm font-light text-gray-500 text-center"
            >
              {item.name}
            </Link>
          ))}
        </div>
        <div className="px-5 flex justify-center space-x-16 my-8">
          {links.map(item => (
            <Link to={item.href} key={item.name}>
              <img src={item.icon} className="h-6" />
            </Link>
          ))}
        </div>
        <div className="font-light text-sm text-center">
          © 2022 House Protocol. All Rights Reserved
        </div>
      </>
    );
  };

  return (
    <>
      <div className="flex-col pt-40 pb-20 items-center hidden md:flex">
        <RenderPc />
      </div>
      <div className="block pb-20 flex-col items-center md:hidden">
        <RenderMb />
      </div>
    </>
  );
};

export default Footer;
