/**
 * @file 请求封装
 */

import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

const BASE_URL = 'https://mail_recv.newbietown.com';

// axios实例
export const request = axios.create({
  // 设置baseUr地址,如果通过proxy跨域可直接填写base地址
  baseURL: BASE_URL,
  // 定义统一的请求头部
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  // 配置请求超时时间
  timeout: 10000,
  // 如果用的JSONP，可以配置此参数带上cookie凭证，如果是代理和CORS不用设置
  // withCredentials: true,
});

request.interceptors.request.use((config: AxiosRequestConfig) => {
  return config;
});

// 返回拦截
request.interceptors.response.use(
  response => {
    const { data } = response;
    if (data.code === 0) {
      return data;
    }
    throw new Error(data.msg);
  },
  error => {
    const { status, data } = error.response;
    if (status !== 200) {
      throw new Error(data.msg);
    }
  }
);
