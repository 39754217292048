import React from 'react';
import { Link } from 'gatsby';

import banner from '../../static/banner.png';
import descBanner from '../../static/descBanner.png';
import dataIcon from '../../static/data.svg';
import transformIcon from '../../static/transform.svg';
import cloudIcon from '../../static/cloud.svg';
import worldIcon from '../../static/world.svg';
import schedule from '../../static/schedule.svg';
import dataCloud from '../../static/dataCloud.svg';
import worldServer from '../../static/worldServer.svg';
import chromeExtension from '../../static/chromeExtension.svg';

import Layout from '../components/Layout';
import Email from '../components/Email';

interface ICardType {
  icon: any;
  href: string;
  title: string;
  desc: string;
  other: string;
}

interface ITimeLineType {
  icon: any;
  href: string;
  title: string;
  time: string;
  desc: string;
  other: string;
}

const cards: ICardType[] = [
  {
    icon: dataIcon,
    href: '#',
    title: 'Wallet Direct Messaging',
    desc: 'The Web3 world is built on top of blockchains and wallet addresses are key to identity in Web3, we make it possible to message anyone in Web3',
    other: '',
  },
  {
    icon: transformIcon,
    href: '#',
    title: 'DAO Communication',
    desc: 'DAOs have complex communications needs, deeply tied to complex smart contracts. We enable this ',
    other: '',
  },
  {
    icon: cloudIcon,
    href: '#',
    title: 'Anti-Discord Scam',
    desc: 'Current DAO operations and NFT/GameFi communities currently face identity fraud and smart contract fraud. Discord identities and bots are far too insecure for Web3 needs.',
    other: '',
  },
  {
    icon: worldIcon,
    href: '#',
    title: 'GameFi Communities ',
    desc: 'Our SDKs and APIs will allow GameFi developers to provide our interoperable, pan-metaverse chat service and build community right in their games. Separate Discord no longer necessary.',
    other: '',
  },
];

const timeLines: ITimeLineType[] = [
  {
    icon: schedule,
    href: '#',
    title: 'Stage 1 — Frontend Plugin POC',
    time: 'Jan 2022',
    desc: 'Frontend — Chrome Plugin POC exploring cross-platform messaging',
    other: 'Backend — Demonet for functionality POC',
  },
  {
    icon: dataCloud,
    href: '#',
    title: 'Stage 2 — Servicenet & SDKs',
    time: 'Q1 2022',
    desc: 'Frontend — production plugin, & SDKs for developers',
    other: 'Backend — non-federated Servicenet powering messaging as a service',
  },
  {
    icon: worldServer,
    href: '#',
    title: 'Stage 3 — Testnet Live',
    time: 'Q3 2022',
    desc: 'Frontend — production plugin, open-source clients, & open-source SDKs',
    other:
      'Backend — federated Testnet (Servicenet still runs production service)',
  },
  {
    icon: worldServer,
    href: '#',
    title: 'Stage 4 — Mainnet Live',
    time: 'Q3 2023',
    desc: 'Frontend — production plugin, open-source clients, & open-source SDKs',
    other: 'Backend — Mainnet Live',
  },
];

const HomePage: React.FC = () => {
  const RenderCard = () => {
    return (
      <div className="flex flex-wrap items-center justify-center text-left sm:-ml-6">
        {cards.map((item: ICardType) => (
          <Link
            key={item.title}
            to={item.href}
            className="h-80 transition duration-300 ease-in-out w-64 mb-6 py-8 px-6 sm:ml-6 rounded-md shadow-lg sm:w-64 lg:mb-0 hover:transform hover:-translate-y-1 hover:scale-105 hover:shadow-2xl"
          >
            <img src={item.icon} className="h-10" />
            <div className="text-lg my-4 font-bold">{item.title}</div>
            <div className="text-sm text-gray-500">{item.desc}</div>
            <div className="my-2" />
            <div className="text-sm text-gray-500">{item.other}</div>
          </Link>
        ))}
      </div>
    );
  };

  const RenderTimeLine = () => {
    return (
      <div className="flex flex-wrap items-center justify-center sm:-ml-6 md:pb-28 lg:border-b border-indigo-500">
        {timeLines.map((item: ITimeLineType) => (
          <Link
            key={item.time}
            to={item.href}
            className="h-96 relative w-64 mb-6 py-8 px-6 sm:ml-6 rounded-lg border-2 border-indigo-500 sm:w-64 lg:mb-0"
          >
            <img src={item.icon} className="h-12 inline" />
            <div className="text-lg mt-6 font-bold">{item.title}</div>
            <div className="text-lg mb-6 font-bold">{item.time}</div>
            <div className="text-sm text-gray-500">{item.desc}</div>
            <div className="h-px bg-gray-200 my-5" />
            <div className="text-sm text-gray-500">{item.other}</div>
            <div className="hidden lg:block absolute -bottom-24 left-1/2 bg-red-500 h-20 w-px -translate-x-1/2" />
            <div className="hidden lg:block absolute -bottom-28 left-1/2 bg-red-500 rounded-full h-3 w-3 -translate-x-1/2 translate-y-2" />
          </Link>
        ))}
      </div>
    );
  };

  const RenderExtension = () => {
    return (
      <div
        className="flex flex-wrap justify-center bg-indigo-600 pt-24 pb-20"
        id="test"
      >
        <div className="relative px-6 flex flex-col items-center lg:items-start lg:mr-6 lg:w-80 ">
          <div className="w-28 h-1.5 absolute left-6 -top-10 bg-white" />
          <div className="mt-1 text-2xl lg:text-3xl font-bold text-white">
            Chrome Extension
          </div>
          <div className="mt-6 text-sm text-white leading-10">
            Experience what only a Web3-native cross-platform messenger unlocks.
            <br />· Message friends across social platforms.
            <br />· Message users directly from NFT marketplaces, propose trades
            directly.
            <br />· Join user communities from NFT marketplaces, mingle, chat,
            and trade with people who share your passion in a Web3 native
            fashion.
          </div>
          <a
            href="https://chrome.google.com/webstore/detail/house-chat/cljogniamdljbpeapjdbdigbjmipfpgh"
            target="_blank"
            className="w-40 my-16 text-sm text-center cursor-pointer font-bold text-indigo-600 rounded-full py-3 bg-white lg:mb-0"
          >
            Add to Chrome
          </a>
        </div>
        <div className="relative">
          <img src={chromeExtension} />
          <div className="w-28 h-1.5 absolute right-6 -bottom-8 bg-white" />
        </div>
      </div>
    );
  };

  return (
    <Layout>
      {/* banner */}
      <div
        className="flex item-center justify-center bg-contain bg-no-repeat bg-center bg-gray-50"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="flex items-center flex-col text-center">
          <div className="text-2xl mt-20 font-bold md:mt-52 md:text-5xl">
            The Pan-Metaverse
          </div>
          <div className="text-2xl font-bold mt-4 md:text-5xl">
            Communications Protocol
          </div>
          <div className="my-6 text-gray-500">
            Enabling communications and transfer of value native to Web3
          </div>
          <div
            className="mb-20 cursor-pointer md:mb-44 font-light text-white rounded-full py-2 px-8 bg-indigo-600"
            onClick={() => {
              const dom = document.getElementById('test');
              dom!.scrollIntoView();
            }}
          >
            JOIN POC
          </div>
        </div>
      </div>
      {/* card */}
      <div className="py-12 text-center md:py-20">
        <div className="text-2xl font-bold md:text-3xl">
          We solve the pains of Web3 communication
        </div>
        <div className="text-sm text-gray-500 md:text-xl mt-2">
          House protocol is a Web3-native communications protocol
        </div>
        <div className="mb-4 text-sm text-gray-500 md:text-xl md:mb-10">
          native to wallets and contracts.
        </div>
        <RenderCard />
      </div>
      {/* timeline */}
      <div
        className="py-16 flex items-center justify-center bg-cover bg-no-repeat md:py-28"
        style={{ backgroundImage: `url(${descBanner})` }}
      >
        <div className="text-xl  md:text-4xl text-white font-bold text-center">
          <div>Build Web3 key community social</div>
          <div>infrastructure in chat</div>
        </div>
      </div>
      <div className="py-12 text-center md:py-24">
        <div className="text-2xl font-bold md:text-3xl">Our Roadmap</div>
        <div className="text-sm text-gray-500 md:text-xl mt-2">
          Building Web3's federated messaging protocol, one step at a time.
          {/* Web3.0 desperately needs a highly efficient, and decentralized
          protocol */}
        </div>
        <div className="mb-4 text-sm text-gray-500 md:text-xl md:mb-14">
          {/* for instant communications */}
        </div>
        <RenderTimeLine />
      </div>
      {/* Chrome Extension */}
      <RenderExtension />
      {/* email */}
      <Email />
    </Layout>
  );
};

export default HomePage;
