import React from 'react';
import { Link } from 'gatsby';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import logo from '../../../static/Web3MQLogo.png';

const navigation = [
  { name: 'Overview', href: '#', current: false },
  { name: 'Mission', href: '#', current: false },
  { name: 'Roadmap', href: '#', current: false },
  { name: 'Whitepaper', href: '#', current: false },
  { name: 'Docs', href: '#', current: false },
  { name: 'Press', href: '#', current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Header = () => {
  return (
    <Disclosure as="nav" className="bg-#FBFBFB border-b border-gray-100">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 md:px-6 md:px-18">
            <div className="flex items-center h-16">
              <div className="flex-none w-16 md:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-grow justify-center flex w-32 md:flex-none md:justify-start ">
                <img
                  className="block md:hidden h-8 w-auto"
                  src={logo}
                  alt="Workflow"
                />
                <img
                  className="hidden md:block h-8 w-auto"
                  src={logo}
                  alt="Workflow"
                />
              </div>
              <div className="flex-grow hidden md:block md:ml-6">
                <div className="flex space-x-16 justify-center">
                  {/* {navigation.map(item => (
                    <Link
                      to={item.href}
                      key={item.name}
                      className="text-base font-light text-gray-500"
                    >
                      {item.name}
                    </Link>
                  ))} */}
                </div>
              </div>
              <div
                className="flex-none hidden md:block w-35 text-sm rounded-full py-2 px-4 border-gray-200 border hover:bg-gray-500 hover:text-white cursor-pointer"
                onClick={() => {
                  const dom = document.getElementById('test');
                  dom!.scrollIntoView();
                }}
              >
                JOIN POC
              </div>
              <div
                className="flex-none md:hidden w-35 text-sm rounded-full py-1 px-2 border-gray-200 border hover:bg-gray-500 hover:text-white cursor-pointer"
                onClick={() => {
                  const dom = document.getElementById('test');
                  dom!.scrollIntoView();
                }}
              >
                JOIN POC
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map(item => (
                <Link
                  to={item.href}
                  key={item.name}
                  className={classNames(
                    item.current
                      ? 'bg-gray-700 text-white'
                      : 'text-gray-500 hover:bg-gray-500 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-light text-base'
                  )}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
