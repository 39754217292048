import React, { useState } from 'react';

import { useInput } from '../../hooks';
import email from '../../../static/email.png';
import { request } from '../../utils/request';

const Email = () => {
  const [error, setError] = useState<boolean>(false);
  const emailInput = useInput('');
  const { input } = emailInput;

  const handleSubmit = async () => {
    const { value } = input;
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!reg.test(value)) {
      return setError(true);
    }

    const params = {
      mail_addr: value,
    };
    try {
      const data: { msg: string } = await request.post('/mail', params);
      alert(data.msg);
    } catch (error: any) {
      alert(error.message);
    }
  };

  return (
    <div
      className="bg-cover bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${email})` }}
    >
      <div className="pt-8 pb-6  md:pt-20 md:pb-10 text-center md:text-4xl font-bold text-white">
        Sign up to get updates on our progress
      </div>
      <div className="relative pb-16 flex justify-center md:pb-24">
        <input
          onFocus={() => setError(false)}
          type="email"
          id="email"
          name="email"
          className="w-60 py-1 px-3 outline-none rounded-full rounded-r-lg md:py-3 md:px-6 md:w-80"
          placeholder="Enter your email address ..."
          {...input}
        />
        <span
          className="px-5 py-1 cursor-pointer rounded-full md:py-3 md:px-10 rounded-l-lg bg-indigo-600 text-white"
          onClick={handleSubmit}
        >
          Subscribe
        </span>
        {error && (
          <span className="absolute text-sm font-bold bottom-10 text-red-500 md:text-lg md:bottom-16">
            Please check that your email address is correct
          </span>
        )}
      </div>
    </div>
  );
};

export default Email;
